/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import { chain } from "underscore" // eslint-disable-line underscore-to-lodash/prefer-import-lodash

function createNewTag(text, done) {
  window.$(".filter-select-candidate").addClass("loading")
  window.$.ajax({ method: "POST", url: "/tags?mode=award", data: { name: text } })
    .done(() => {
      done({ text })
      window.$(".filter-select-candidate").removeClass("loading")
    })
    .fail((xhr) => {
      console.log(xhr)
    })
}

export function toggleSelectionIcon(option) {
  const id = this.attr("id")
  if (id === "award-tag-include" || id === "teams-include" || id === "tags") {
    option.toggleClass("included")
  } else {
    option.toggleClass("excluded")
  }
}

export function init_filter_selects(rule) {
  rule.container.find(".award-tag-select-js").filterSelect({
    callback: toggleSelectionIcon,
    createCallback: createNewTag,
    type: "footer",
    show: 6,
    footerShow: 6,
    showCreate: true,
    createPrompt: "Type a name to create tags",
    appendFooter() {
      if (this.parents(".tab-pane").length > 0) {
        return this.parents(".tab-pane")
      }
      return this.parents(".tag-search")
    },
  })

  rule.container.find(".team-select-js").filterSelect({
    callback: toggleSelectionIcon,
    type: "footer",
    show: 6,
    footerShow: 6,
    appendFooter() {
      return this.parents(".tab-pane")
    },
  })
}

export function get_tag_values(...args) {
  return chain(args)
    .compact()
    .map((list) => list.toString().split(","))
    .flatten()
    .uniq()
    .value()
    .join(",")
}

export function is_updated(value) {
  this.hidden = value
  const el = this.element
  if (value) {
    el.fadeOut(200, () => el.addClass("hide"))
  } else {
    el.fadeIn(200, () => el.addClass("hide"))
  }
}
