/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import steps from "./steps"
import { init_filter_selects } from "./helpers"

export default {
  init(default_params) {
    const rule = (window.rule = new window.Ruleable("allowance", ".allowances-accordion", steps, default_params))
    init_filter_selects(rule)
    rule.container.find(".multi-select-js").multi_select()
    rule.container.find(".select-box").selectInput()
    rule.container.find(".time-input-parent").time_input()
    window.$(".range-input").range_input()

    window
      .$(".allowances-accordion")
      .find("[data-sets-manual]")
      .bigRadioButtons(function (ele) {
        const val = ele.getAttribute("data-sets-manual") === "true"
        const allpval = ele.getAttribute("data-sets-all-purpose") === "true"
        rule.update_props("is_manual", val, "is_all_purpose", allpval)
        rule.update_step_numbers()
      })

    rule.steps[0].open_accordion()

    window.$("form[id*=allowance]").submit(function () {
      rule.prepare_for_post()
      return true // continue as you were
    })

    return rule
  },
}
