/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

// if you *really* need to add new jquery functionality to this file
// you will need to declare it here: app/assets/webpack/award_rules/.eslintrc.js
// but please try and avoid doing that if possible

import { chain, compact, each } from "underscore" // eslint-disable-line underscore-to-lodash/prefer-import-lodash
import { t } from "helpers/i18n"

function createNewTag(text, done) {
  window.$(".filter-select-candidate").addClass("loading")
  window.$.ajax({ method: "POST", url: "/tags?mode=award", data: { name: text } })
    .done(function () {
      done({ text })
      window.$(".filter-select-candidate").removeClass("loading")
    })
    .fail(function (xhr) {
      console.error(xhr)
    })
}

function toggleSelectionIcon(option) {
  const id = this.attr("id")
  if (
    id === "award-tag-include-awards" ||
    id === "teams-include-awards" ||
    id === "award-staff-tags" ||
    id === "negative-award-staff-tags" ||
    id === "award-leave-tags"
  ) {
    option.toggleClass("included")
  } else {
    option.toggleClass("excluded")
  }
}

function get_tag_values() {
  const tags = chain(arguments)
    .compact()
    .map(function (list) {
      return list.toString().split(",")
    })
    .flatten()
    .uniq()
    .value()

  return tags.join(",")
}

function toggleAccordionSubsection() {
  const sub_accordion_heading = window.$(".sub-accordion")
  const sub_accordion_body = window.$(".accordion-small-body")

  sub_accordion_heading.click(function () {
    const $this = window.$(this)
    $this.parents(".multi-accordion-container").find(sub_accordion_heading).toggleClass("selected")
    $this.parents(".multi-accordion-container").find(sub_accordion_body).slideToggle(200) // eslint-disable-line jquery/no-slide
  })
}

// function for adding time input

function addTimeRow() {
  const add_button = window.$("#new-hours-container")

  add_button.click(function () {
    const parent = add_button.parents(".time-input-field")
    const hidden = parent.find("[type=hidden]")
    hidden[0].add_new_row(hidden)
  })
}

function preventTimeDefault() {
  const select_input = window.$(".time-input .select-input")

  select_input.click(function (e) {
    const $this = window.$(this)
    if (!$this.parents(".time-input").hasClass("active")) {
      e.stopPropagation()
    }
  })
}

function preventSingleTimeDefault() {
  const select_input = window.$(".single-time-input .select-input")

  select_input.click(function (e) {
    const $this = window.$(this)
    if (!$this.parents(".single-time-input").hasClass("active")) {
      e.stopPropagation()
    }
  })
}

function toggleAccordionBorder() {
  const accordion_radio = window.$(".accordion-radio")

  accordion_radio.click(function () {
    const $this = window.$(this)

    if ($this.hasClass("selected")) {
      $this.removeClass("bottom-border")
      $this.siblings().addClass("bottom-border")
    } else {
      $this.siblings().removeClass("bottom-border")
    }
  })
}

function toggleTimeInput() {
  const option_box = window.$("#award-time-input").find(".option-box")
  const hours_worked_option = window.$("#worked-between-container").find(".option-box")

  option_box.click(function () {
    const $this = window.$(this)

    $this.siblings(".time-input").toggleClass("active")
    $this
      .parents("#worked-between-container")
      .siblings("#worked-between")
      .find(".time-inputs-overarching")
      .children(".time-input")
      .toggleClass("active")
    if (!$this.hasClass("selected")) {
      $this.siblings(".time-input").find("select").val("1").trigger("change")
      $this
        .parents("#worked-between-container")
        .siblings("#worked-between")
        .find(".time-inputs-overarching")
        .children(".time-input")
        .find("select")
        .val("1")
        .trigger("change")
    }
  })

  hours_worked_option.click(function () {
    const $this = window.$(this)

    if (!$this.hasClass("selected")) {
      $this
        .parents("#worked-between-container")
        .siblings("#worked-between")
        .children(".time-inputs-overarching")
        .find(".additional-time-container")
        .remove()
    }
  })
}

function toggleSingleTimeInput() {
  const option_box = window.$(".single-time-input-toggle-parent-js").find(".option-box")
  option_box.click(function () {
    const $this = window.$(this)

    $this.siblings(".single-time-input").toggleClass("active")
    if (!$this.hasClass("selected")) {
      $this.siblings(".single-time-input").find("select").val("1").trigger("change")
    }
  })
}

function checkShowRosteredHours() {
  const option_box = window.$("#rostered-hours-option")
  const checkboxes = option_box.siblings(".checkbox-layout")
  const roster_overtime_checkbox = checkboxes.children("#roster_overtime")

  if (option_box.siblings(".checkbox-layout").find(":checked").length > 0) {
    checkboxes.removeClass("hidden")
    option_box.addClass("selected")
  }

  option_box.click(function () {
    checkboxes.toggleClass("hidden")
    if (!option_box.hasClass("selected")) {
      option_box.siblings(".checkbox-layout").find(":checked").prop("checked", false)
    }

    if (option_box.siblings(".checkbox-layout").find(":checked").length === 0) {
      option_box.siblings().find("#award_roster_overtime").val("true")
      roster_overtime_checkbox.find(":checkbox").prop("checked", true)
      roster_overtime_checkbox.addClass("selected")
    }
  })
}

function toggleHideShiftGapEntireHoursCheckbox() {
  const shift_gap_ord_hours_element = window.$("#award_shift_gap_ord_hours")
  const value = parseFloat(shift_gap_ord_hours_element.val())
  const checkbox = window.$("#ord-hours-entire-shift")
  const checkboxElement = checkbox.find("input[type='checkbox']")

  if (value > 0) {
    checkbox.removeClass("hidden")
  } else {
    checkbox.addClass("hidden")
    checkboxElement.prop("checked", false)
    checkbox.removeClass("selected")
  }
}

function toggleShiftGapEntireHoursCheckboxValue() {
  const checkbox = window.$("#ord-hours-entire-shift")
  const checkboxElement = checkbox.find("input[type='checkbox']")

  checkboxElement.click(function () {
    if (checkboxElement.is(":checked")) {
      checkbox.addClass("selected")
    } else {
      checkbox.removeClass("selected")
    }
  })
}

function toggleRosterdHoursCheckboxes() {
  const option_box = window.$("#rostered-hours-option")
  const checkboxes = option_box.siblings(".checkbox-layout")

  const roster_overtime_checkbox = checkboxes.children("#roster_overtime")
  const max_rostered_hours_checkbox = checkboxes.children("#max_rostered_hours")

  const roster_overtime_checkbox_element = roster_overtime_checkbox.find(":checkbox")
  const max_rostered_hours_checkbox_element = max_rostered_hours_checkbox.find(":checkbox")

  function uncheckRosterOvertime() {
    roster_overtime_checkbox_element.prop("checked", false).trigger("change")
  }

  function unselectRosterOvertime() {
    option_box.siblings().find("#award_roster_overtime").val("false")
    roster_overtime_checkbox.removeClass("selected")
  }

  function uncheckMaxRosteredHours() {
    max_rostered_hours_checkbox_element.prop("checked", false).trigger("change")
  }

  function unselectMaxRosteredHours() {
    option_box.siblings().find("#award_max_rostered_hours").val("false")
    max_rostered_hours_checkbox.removeClass("selected")
  }

  roster_overtime_checkbox_element.change(function (e) {
    if (e.target.checked) {
      uncheckMaxRosteredHours()

      option_box.siblings().find("#award_roster_overtime").val("true")
      roster_overtime_checkbox.addClass("selected")
    } else {
      unselectRosterOvertime()
    }
  })

  max_rostered_hours_checkbox_element.change(function (e) {
    if (e.target.checked) {
      uncheckRosterOvertime()

      option_box.siblings().find("#award_max_rostered_hours").val("true")
      max_rostered_hours_checkbox.addClass("selected")
    } else {
      unselectMaxRosteredHours()
    }
  })

  // Uncheck options if Rostered hours rule is clicked
  option_box.click(function () {
    uncheckRosterOvertime()
    uncheckMaxRosteredHours()
  })
}

function removeSubAccordionOptions() {
  const sub_accordion = window.$(".sub-accordion")
  const sub_accordion_body = window.$(".accordion-small-body")

  sub_accordion.click(function () {
    const $this = window.$(this)

    if (!$this.hasClass("selected")) {
      const this_selection = $this.parents(".multi-accordion-container").find(sub_accordion_body)
      this_selection.find(".option-box").removeClass("selected")
      this_selection.find(".ordinary-hour-container").find(".stretch-input").hide()
      this_selection.find("input").val("")
      this_selection.find(".advanced-options").hide()
    }
  })
}

function init_filter_selects(rule) {
  rule.container.find(".award-tag-select-js").filterSelect({
    callback: toggleSelectionIcon,
    createCallback: createNewTag,
    type: "footer",
    show: 6,
    footerShow: 6,
    showCreate: true,
    createPrompt: t("js.awards.form.tag_filter_select.placeholder"),
    appendFooter: function () {
      if (this.parents(".tab-pane").length > 0) {
        return this.parents(".tab-pane")
      }
      return this.parents(".tag-search")
    },
  })

  rule.container.find(".team-select-js").filterSelect({
    callback: toggleSelectionIcon,
    type: "footer",
    show: 6,
    footerShow: 6,
    appendFooter: function () {
      return this.parents(".tab-pane")
    },
  })

  rule.container.find(".public-holiday-select-js").filterSelect({
    callback: toggleSelectionIcon,
    createCallback: (name, done) => done({ text: name }),
    type: "footer",
    show: 6,
    footerShow: 6,
    showCreate: true,
    createPrompt: t("js.awards.form.ph_filter_select.placeholder"),
    appendFooter: function () {
      if (this.parents(".tab-pane").length > 0) {
        return this.parents(".tab-pane")
      }
      return this.parents(".tag-search")
    },
  })

  rule.container.find(".leave-type-select-js").filterSelect({
    callback: toggleSelectionIcon,
    createCallback: (name, done) => done({ text: name }),
    type: "footer",
    show: 6,
    footerShow: 6,
    showCreate: true,
    createPrompt: t("js.awards.form.ph_filter_select.placeholder"),
    appendFooter: function () {
      if (this.parents(".tab-pane").length > 0) {
        return this.parents(".tab-pane")
      }
      return this.parents(".tag-search")
    },
  })
}

function configureOrdinaryHoursToggle() {
  const hidden = window.$("#award_ordinary_hours")
  const area = window.$("#ordinary-hours-toggle")

  function updateDOMFromHidden() {
    area.find("[data-toggle-val]").removeClass("btn-theme-primary").addClass("btn-ghost")
    area
      .find("[data-toggle-val=" + hidden.val() + "]")
      .removeClass("btn-ghost")
      .addClass("btn-theme-primary")
  }

  area.on("click", "a", function () {
    hidden.val(window.$(this).data("toggle-val"))
    updateDOMFromHidden()
  })

  updateDOMFromHidden()
}

function handleDaysWorkedVisual() {
  const visuals = window.$(".days-worked-visual")
  visuals.each(function () {
    const visual = window.$(this)
    const hidden = visual.find("[type=hidden]")
    const day_option = visual.find(".day-option")
    const accordion = day_option.parents(".accordion-group").eq(0)
    const applies_label = visual.find(".applies-label")

    const onlyShowLastAppliesLabel = () => {
      const l1 = visuals.first().find(".applies-label")
      const v1val = visuals.first().find("[type=hidden]")
      const v2val = visuals.last().find("[type=hidden]")
      // if we have a value for the second visual,
      // hide the "applies here" label on the first visual
      if (v2val.length && v2val.val() && v2val.val() !== "0") {
        l1.removeClass("show")
      } else if (v1val.length && v1val.val() && v1val.val() !== "0") {
        l1.addClass("show")
      }
    }

    function markAsSelectedAndReturnIfValueShouldBeUsed(index, deselect_if_current) {
      if (Number.isNaN(index) || index < 0) {
        day_option.removeClass("current selected applies")
        applies_label.removeClass("show")
        return false
      } else {
        const $this = day_option.eq(index)
        const siblings = $this.siblings()
        const before = siblings.slice(0, index)
        const after = siblings.slice(index)

        if (deselect_if_current && $this.hasClass("current")) {
          before.removeClass("selected")
          after.removeClass("applies")
          $this.removeClass("current selected")
          applies_label.removeClass("show")
          return false
        } else {
          applies_label.addClass("show")

          before.addClass("selected").removeClass("applies").removeClass("current")
          after.addClass("applies").removeClass("selected").removeClass("current")
          $this.addClass("selected").addClass("current").removeClass("applies")

          const after_length = after.length

          const accordion_offset = accordion.offset().left
          const bubble_half_width = 16
          const applies_label_width = applies_label.width()
          const center_index = after_length / 2
          let offset
          if (after_length % 2 === 0) {
            const left_of_center_index = center_index - 1
            const right_of_center_index = center_index
            const left_of_center = after.eq(left_of_center_index)
            const right_of_center = after.eq(right_of_center_index)

            const left_offset = left_of_center.offset().left
            const right_offset = right_of_center.offset().left
            offset = left_offset + (right_offset - left_offset + 32) / 2
          } else {
            const center = window.$(after[Math.floor(center_index)])
            offset = center.offset().left + bubble_half_width
          }
          const left = offset - accordion_offset - applies_label_width / 2
          applies_label.css({ left })
          return true
        }
      }
    }

    hidden.change(function () {
      const num = parseFloat(window.$(this).val())
      markAsSelectedAndReturnIfValueShouldBeUsed(num - 1)
      onlyShowLastAppliesLabel()
    })

    day_option.filter(":not(:last-of-type)").click(function () {
      const num = parseFloat(window.$(this).text())
      if (markAsSelectedAndReturnIfValueShouldBeUsed(num - 1, true)) {
        hidden.val(num)
      } else {
        hidden.val("")
      }
      onlyShowLastAppliesLabel()
    })

    const current_val = parseFloat(hidden.val())
    markAsSelectedAndReturnIfValueShouldBeUsed(current_val - 1)
    onlyShowLastAppliesLabel()

    visual.on("show", function () {
      const current_val = parseFloat(hidden.val())
      markAsSelectedAndReturnIfValueShouldBeUsed(current_val - 1)
      onlyShowLastAppliesLabel()
    })
  })
}

function init(default_params) {
  // all your code in here

  const steps = [
    // step 1
    {
      name: t("js.awards.form.title.name"),
      validator: function () {
        return !!this.element.find("#award_name").val()
      },
    },
    // step 2
    {
      name: t("js.awards.form.title.who"),
      validator: function validator() {
        const tags_string = this.state.tags
        const negative_tags_string = this.state.negative_tags
        const tags_array = tags_string.split(",").filter((item) => item.trim() !== "")
        const negative_tags_array = negative_tags_string.split(",").filter((item) => item.trim() !== "")

        if (this.state.everyone === "0" && tags_array.length === 0) {
          return false
        }
        if (tags_array.some((item) => negative_tags_array.includes(item))) {
          return false
        }
        if (this.state.everyone === "1" && negative_tags_array.length > 0) {
          return false
        }

        return true
      },
      after_state_update: function after_state_update() {
        const footer_fixed = this.element.find(".tag-search").find(".filter-select-footer")

        if (this.state.everyone === "1") {
          footer_fixed.addClass("hide-footer")
        } else if (this.state.everyone !== "1") {
          window.$(".hide-footer").removeClass("hide-footer")
        }
      },
      title_updater: function title_updater() {
        // if the provided title is not empty, use that instead
        // get the age val, if needed
        let age_title
        if (this.element.find(".advanced-link").hasClass("open")) {
          age_title = this.element.find(".range-input").get(0).get_title_wording()
        }
        const title = age_title ? " aged ".concat(age_title) : ""

        if (this.get_title()) {
          return this.get_title() + title
        }
        const el = this.element.find("select").get(0)
        if (el.is_filter_select) {
          const tags = this.element.find("select").get(0).filter_select_instance.val()

          return "Tags: ".concat(window.joinArray(tags, 2, "and", "other tag")) + title
        }
        return false
      },
    },
    // step 3
    {
      name: t("js.awards.form.title.hours_worked"),
      checkbox_accordion: true,
      opened: true,
      is_valid: true,
      is_unchecked: function () {
        this.element.find("#award_max_length").val("")
        this.element.find("#daily-hours-input").hide()
        this.element.find("#span-of-hours-input").hide()
        this.element.find("#multi-day-max-length-input").hide()
        this.element.find(".accordion-small-body").hide()
        this.element.find("#award_overtime").val("")
        this.element.find("#pay-period-hours-input").hide()
        this.element.find("#award_applies_after_penalties").val("")
        this.element.find("#award_applies_after_timesheet_week_penalties").val("")
        this.element.find("#award_roster_overtime_hours").val("")
        this.element.find("#award_roster_overtime").val("")
        this.element.find("#award_beyond_fixed_hours_overtime").val("")
        this.element.find("#award_beyond_fixed_hours").val("")
        this.element.find("#award_beyond_contracted_hours").val("")
        this.element.find(".overtime-hour-container").hide()
        this.element.find(".signifier").removeClass("visible")
        this.element.find(".ordinary-hour-container .stretch-input").hide()
        this.element
          .find("#standard-overtime-accordion, #penalty-overtime-accordion, #all-hour-overtime-accordion")
          .removeClass("selected bottom-border")
        this.element.find(".option-box").removeClass("selected")
      },
      validator: function () {
        const standard_overtime = this.element.find("#standard-overtime-accordion")
        const penalty_overtime = this.element.find("#penalty-overtime-accordion")
        const all_hour_overtime = this.element.find("#all-hour-overtime-accordion")

        if (standard_overtime.hasClass("selected") || all_hour_overtime.hasClass("selected")) {
          return this.element.find("#ordinary-hours .option-box.selected").length > 0
        }

        if (penalty_overtime.hasClass("selected")) {
          return (
            this.element.find(".penalty-input-container input").filter(function () {
              return +window.$(this).val() > 0
            }).length > 0
          )
        }

        return false
      },
      title_updater: function title_updater() {
        let overtime = ""

        if (
          window.$("#award_applies_after_penalties").val() ||
          window.$("#award_applies_after_timesheet_week_penalties").val() ||
          window.$("#award_roster_overtime_hours").val() ||
          window.$("#award_beyond_fixed_hours").val() ||
          window.$("#award_beyond_contracted_hours")
        ) {
          overtime = t("js.awards.form.title.overtime_hours")
        }
        const option_title = this.get_title()

        if (option_title && overtime) {
          return t("js.awards.form.hours_worked_title_updater", { option_title: option_title, overtime: overtime })
        } else {
          return option_title || overtime
        }
      },
      is_opening: function is_opening() {
        if (
          this.element
            .find("#standard-overtime-accordion, #penalty-overtime-accordion, #all-hour-overtime-accordion")
            .filter(".selected").length === 0
        ) {
          // by default select the regular overtime type
          this.element.find("#standard-overtime-accordion").addClass("selected")
          this.element.find("#penalty-overtime-accordion").addClass("bottom-border")
        }
      },
      on_form_load: function () {
        const all_hour_overtime = this.element.find("#all-hour-overtime-accordion")
        const overtime_question_ordinary = this.element.find("#overtime-question-ordinary")
        const overtime_question_combined = this.element.find("#overtime-question-combined")
        const element = this.element
        const option_box_inputs = element.find(".option-box")

        function floatHasValue(val) {
          return !!val && parseFloat(val) !== 0
        }

        function hiddenHasValue(val) {
          return !!val && val !== "false"
        }

        // if an input has a value, select the corresponding option box
        function toggleOptionBoxSelected() {
          const input = window.$(this).siblings(".value-provider").first().find("input")
          let should_show = hiddenHasValue(input.val())

          if (!should_show && window.$(this).data("value-provider")) {
            const data_provider_value = element.find(window.$(this).data("value-provider")).val()
            should_show = floatHasValue(data_provider_value)
          }

          if (should_show) {
            window.$(this).addClass("selected").trigger("update")
          } else {
            window.$(this).removeClass("selected").trigger("update")
          }

          if (window.$(this).parents("[data-advanced-link]").length) {
            const al_data_provider_value = element.find(window.$(this).data("value-provider")).val()
            if (floatHasValue(al_data_provider_value)) {
              const advanced_link = element.find(
                window.$(window.$(this).parents("[data-advanced-link]").data("advanced-link"))
              )
              advanced_link.addClass("open")
            }
          }
        }
        option_box_inputs.each(toggleOptionBoxSelected)
        if (all_hour_overtime.prop("value") === 1) {
          overtime_question_ordinary.hide()
          overtime_question_combined.show()
        } else {
          overtime_question_combined.hide()
          overtime_question_ordinary.show()
        }

        // open the correct section by default - based on which inputs have data
        function selectSection(open, closed) {
          element.find(open).addClass("selected")
          element.find(closed).addClass("bottom-border")
        }

        if (element.find("#award_considers_all_hours").val() === "true") {
          selectSection("#all-hour-overtime-accordion", "#standard-overtime-accordion, #penalty-overtime-accordion")
        } else if (
          floatHasValue(element.find("#award_roster_overtime_hours").val()) ||
          floatHasValue(element.find("#award_beyond_fixed_hours").val()) ||
          floatHasValue(element.find("#award_applies_after_penalties").val()) ||
          floatHasValue(element.find("#award_applies_after_timesheet_week_penalties").val())
        ) {
          selectSection("#penalty-overtime-accordion", "#all-hour-overtime-accordion, #standard-overtime-accordion")
        } else if (
          floatHasValue(element.find("#award_max_length").val()) ||
          floatHasValue(element.find("#award_span_of_hours").val()) ||
          floatHasValue(element.find("#award_overtime").val()) ||
          hiddenHasValue(element.find("#award_roster_overtime").val()) ||
          hiddenHasValue(element.find("#award_max_rostered_hours").val()) ||
          hiddenHasValue(element.find("#award_beyond_fixed_hours_overtime").val())
        ) {
          selectSection("#standard-overtime-accordion", "#all-hour-overtime-accordion, #penalty-overtime-accordion")
        }
      },
      after_state_update: function after_state_update() {
        const accordion_radio = this.element.find(".accordion-radio")
        const standard_overtime = this.element.find("#standard-overtime-accordion")
        const all_hour_overtime = this.element.find("#all-hour-overtime-accordion")
        const penalty_overtime = this.element.find("#penalty-hours")
        const considers_all_hours = this.element.find("#award_considers_all_hours")
        const overtime_question_ordinary = this.element.find("#overtime-question-ordinary")
        const overtime_question_combined = this.element.find("#overtime-question-combined")
        // remove penalty hour amounts
        if (standard_overtime.hasClass("selected") || all_hour_overtime.hasClass("selected")) {
          penalty_overtime.find("input").val("")
        }

        if (accordion_radio.hasClass("selected")) {
          accordion_radio.parents(".overtime-options-container").next(".accordion-small-body").slideDown(200)
        }

        considers_all_hours.val(all_hour_overtime.hasClass("selected"))

        if (considers_all_hours.val() === "true") {
          overtime_question_ordinary.hide()
          overtime_question_combined.show()
        } else {
          overtime_question_combined.hide()
          overtime_question_ordinary.show()
        }

        if (
          this.element.find("#rostered-hours-option").hasClass("selected") ||
          this.element.find("#pt-fixed-option").hasClass("selected")
        ) {
          this.element.find("#standard-ot").hide()
          this.element.find("#standard-ot input").val("")
        } else {
          this.element.find("#standard-ot").show()
          this.element.find("#award_roster_overtime_hours").val("")
          this.element.find("#award_beyond_fixed_hours").val("")
          this.element.find("#award_beyond_contracted_hours").val("")
        }
      },
    },
    // Step 4
    {
      name: t("js.awards.form.title.consecutive"),
      checkbox_accordion: true,
      opened: true,
      is_valid: true,
      is_unchecked: function () {
        this.element.find("#award_overtime_after_consecutive_days_worked").val("").trigger("change")
        this.element.find("#award_consecutive_daily_ord_hours").val("").trigger("change")
        this.element.find("#award_consecutive_hours_break").val("").trigger("change")
      },
      validator: function () {
        const max = parseFloat(this.element.find("#award_overtime_after_consecutive_days_worked")[0]["max"])
        return (
          this.state.overtime_after_consecutive_days_worked !== "" &&
          this.state.overtime_after_consecutive_days_worked <= max
        )
      },
      title_updater: function () {
        if (!this.validator()) {
          return ""
        }

        const days_count = parseFloat(this.state.overtime_after_consecutive_days_worked || "0")
        const min_daily_hours = parseFloat(this.state.consecutive_daily_ord_hours || "0")
        const min_rest_after = parseFloat(this.state.consecutive_hours_break || "0")

        const title_string = []
        if (days_count) {
          title_string.push(t("js.awards.form.subtitle.consecutive", { count: days_count }))
        }
        if (min_daily_hours) {
          title_string.push(t("js.awards.form.subtitle.consecutive_hour", { count: min_daily_hours }))
        }
        if (min_rest_after) {
          title_string.push(t("js.awards.form.subtitle.consecutive_break", { count: min_rest_after }))
        }
        return title_string.join(", ")
      },
    },
    // Step 5
    {
      name: t("js.awards.form.title.consecutive_off"),
      checkbox_accordion: true,
      opened: true,
      is_valid: true,
      is_unchecked: function () {
        this.element.find("#award_weekly_days_off_required").val("").trigger("change")
        this.element.find("#award_fortnightly_days_off_required").val("").trigger("change")
      },
      validator: function () {
        const weekly_requirement_valid =
          this.state.weekly_days_off_required &&
          this.state.weekly_days_off_required >= 0 &&
          this.state.weekly_days_off_required <= 6
        const fortnightly_requirement_valid =
          this.state.fortnightly_days_off_required &&
          this.state.fortnightly_days_off_required >= 0 &&
          this.state.fortnightly_days_off_required <= 13

        return weekly_requirement_valid || fortnightly_requirement_valid
      },
      title_updater: function () {
        if (!this.validator()) {
          return ""
        }

        const weekly_required = parseFloat(this.state.weekly_days_off_required) || 0
        const fortnightly_required = parseFloat(this.state.fortnightly_days_off_required) || 0

        const title_string = []
        if (fortnightly_required) {
          title_string.push(
            t("js.awards.form.subtitle.consecutive_fortnightly_days_off_required", { count: fortnightly_required })
          )
        }
        if (weekly_required) {
          title_string.push(
            t("js.awards.form.subtitle.consecutive_weekly_days_off_required", { count: weekly_required })
          )
        }

        return title_string.join(", ")
      },
      after_state_update: function () {
        const weekly_requirement_valid =
          this.state.weekly_days_off_required > 0 && this.state.weekly_days_off_required <= 6
        const fortnightly_requirement_valid =
          this.state.fortnightly_days_off_required > 0 && this.state.fortnightly_days_off_required <= 13

        if (this.state.fortnightly_days_off_required && !fortnightly_requirement_valid) {
          this.element.find("#award_fortnightly_days_off_required").val("").trigger("change")
        }

        if (this.state.weekly_days_off_required && !weekly_requirement_valid) {
          this.element.find("#award_weekly_days_off_required").val("").trigger("change")
        }
      },
    },
    // Step 6
    {
      name: t("js.awards.form.title.rolling_overtime"),
      checkbox_accordion: true,
      opened: true,
      is_valid: true,
      is_unchecked: function () {
        this.element.find("#award_rolling_ot_window").val("").trigger("change")
        this.element.find("#award_rolling_ot_threshold").val("").trigger("change")
      },
      validator: function () {
        const window = parseFloat(this.state.rolling_ot_window || "0")
        const hours = parseFloat(this.state.rolling_ot_threshold || "0")
        return window !== "" && hours !== "" && window > hours
      },
      title_updater: function () {
        if (!this.validator()) {
          return ""
        }

        const window_length = parseFloat(this.state.rolling_ot_window || "0")
        const hours_length = parseFloat(this.state.rolling_ot_threshold || "0")
        const title_string = []
        if (window_length && hours_length && window_length > hours_length) {
          title_string.push(
            t("js.awards.form.subtitle.rolling_window_length", {
              threshold: hours_length,
              window_length: window_length,
            })
          )
        }

        return title_string.join(", ")
      },
    },
    // Step 7
    {
      name: t("js.awards.form.title.days_worked_week"),
      checkbox_accordion: true,
      opened: true,
      is_valid: true,
      is_unchecked: function () {
        this.element.find("#award_overtime_after_days_worked").val("").trigger("change")
        this.element.find("#award_max_days_in_period").val("").trigger("change")
        this.element.find("#award_timesheet_weeks_for_max_days").val("").trigger("change")
        this.element.find("#award_max_days_in_period_daily_ord_hours").val("").trigger("change")
        this.element.find("#overtime_averaging_period_checkbox").removeClass("selected").trigger("change")
      },
      validator: function () {
        const max_days_in_period = parseFloat(this.state.max_days_in_period) || 0
        const timesheet_weeks_for_max_days = parseFloat(this.state.timesheet_weeks_for_max_days) || 0
        const normalValidation =
          this.state.overtime_after_days_worked !== "" && this.state.overtime_after_days_worked !== "0"
        const customValidation = max_days_in_period !== "" && max_days_in_period !== 0
        const overtime_averaging_period_checkbox = this.element
          .find("#overtime_averaging_period_checkbox")
          .hasClass("selected")
        const timesheet_weeks_for_max_days_state =
          timesheet_weeks_for_max_days !== "" && timesheet_weeks_for_max_days !== 0
        const periodValidation =
          (timesheet_weeks_for_max_days_state || overtime_averaging_period_checkbox) &&
          !(timesheet_weeks_for_max_days_state && overtime_averaging_period_checkbox)
        const isTimesheetWeeksValid = timesheet_weeks_for_max_days * 7 > max_days_in_period

        if (normalValidation) {
          return "normal"
        }
        if (customValidation && periodValidation && isTimesheetWeeksValid) {
          return "custom"
        }
        return false
      },
      get_sections: function () {
        const customPeriodButton = this.element.find(".custom-period-btn")
        const normalPeriodButton = this.element.find(".normal-period-btn")
        const customPeriodSection = this.element.find(".custom-period-section")
        const normalPeriodSection = this.element.find(".normal-period-section")

        return { customPeriodButton, normalPeriodButton, customPeriodSection, normalPeriodSection }
      },
      title_updater: function () {
        if (!this.validator()) {
          return ""
        }

        if (this.validator() === "normal") {
          const { overtime_after_days_worked, overtime_after_days_worked_second_week } = this.state
          const days_worked_week_one = parseFloat(overtime_after_days_worked || "0")
          const days_worked_week_two = parseFloat(overtime_after_days_worked_second_week || "0")

          if (days_worked_week_two) {
            // Original I18n files use 'a' and 'b' variables
            const a = days_worked_week_one
            const b = days_worked_week_two
            return t("js.awards.form.subtitle.days_worked_second_week", { a, b })
          } else if (days_worked_week_one) {
            return t("js.awards.form.subtitle.days_worked", { count: days_worked_week_one })
          }
        }

        if (this.validator() === "custom") {
          const { max_days_in_period, max_days_in_period_daily_ord_hours, timesheet_weeks_for_max_days } = this.state
          const timesheet_weeks_for_max_days_state =
            timesheet_weeks_for_max_days !== "" && timesheet_weeks_for_max_days !== "0.0"

          return [
            max_days_in_period &&
              !timesheet_weeks_for_max_days_state &&
              t("js.awards.form.subtitle.custom_period_overtime_averaging", {
                max_days: parseFloat(max_days_in_period),
              }),
            max_days_in_period &&
              timesheet_weeks_for_max_days_state &&
              (() => {
                const weeks = parseFloat(timesheet_weeks_for_max_days)
                const translationKey =
                  weeks === 1
                    ? "js.awards.form.subtitle.custom_period_another_period.one"
                    : "js.awards.form.subtitle.custom_period_another_period.other"

                return t(translationKey, {
                  max_days: parseFloat(max_days_in_period),
                  another_period_length: weeks,
                })
              })(),
            max_days_in_period_daily_ord_hours &&
              t("js.awards.form.subtitle.custom_period_hours", {
                hours: parseFloat(max_days_in_period_daily_ord_hours),
              }),
          ]
            .filter((val) => val)
            .join(", ")
        }
      },
      expand_custom: function () {
        const { customPeriodButton, normalPeriodButton, customPeriodSection, normalPeriodSection } = this.get_sections()
        normalPeriodSection.hide()
        normalPeriodButton.removeClass("selected")
        customPeriodSection.show()
        customPeriodButton.addClass("selected")
      },
      expand_normal: function () {
        const { customPeriodButton, normalPeriodButton, customPeriodSection, normalPeriodSection } = this.get_sections()
        customPeriodSection.hide()
        customPeriodButton.removeClass("selected")
        normalPeriodSection.show()
        normalPeriodButton.addClass("selected")
      },
      on_form_load: function () {
        const { customPeriodButton, normalPeriodButton, customPeriodSection, normalPeriodSection } = this.get_sections()
        customPeriodButton.click(() => {
          this.expand_custom()
        })

        normalPeriodButton.click(() => {
          this.expand_normal()
        })
        normalPeriodSection.hide()
        customPeriodSection.hide()
      },
      has_opened: function () {
        if (this.validator() === "normal" || !this.validator()) {
          this.expand_normal()
        }
        if (this.validator() === "custom") {
          this.expand_custom()
        }
      },
    },
    // Step 8
    {
      name: t("js.awards.form.title.day"),
      checkbox_accordion: true,
      opened: true,
      is_valid: true,
      is_unchecked: function () {
        this.state.public_holidays = 0
        this.state.public_holiday_names = ""
        this.state.days = ""
        this.state.always = 0
        this.element.find(".selected").removeClass("selected")
      },
      validator: function validator() {
        const public_holidays = this.state.public_holidays
        const days = this.state.days

        if (public_holidays === "1" && days === "0,1,2,3,4,5,6") {
          return false
        } else {
          return public_holidays === "1" || days !== ""
        }
      },
      after_state_update: function after_state_update() {
        const default_option = this.element.find(".default-option-info")
        this.element
          .find(".specific-day-container")
          .toggleClass("blue-border", this.state.public_holidays !== "1" && this.state.always !== "1")

        if (this.state.public_holidays === "1") {
          this.element.find(".public-hol-names-container-js").show()
        } else {
          this.element.find(".public-hol-names-container-js").hide()
        }
        if (this.state.public_holidays === "1" || this.state.days !== "") {
          this.state.always = 0
          this.element.find("#award_always").val(0)
          default_option.addClass("dull")
        } else {
          this.state.always = 1
          this.element.find("#award_always").val(1)
          default_option.removeClass("dull")
        }
      },
      title_updater: function title_updater() {
        const days = this.element
          .find("#specific-days-holder-awards .selected")
          .map(function () {
            return this.innerText
          })
          .get()
        if (this.state.public_holidays === "1" && this.state.days !== "") {
          return "Public Holidays on ".concat(window.joinArray(days, 3, "and", "other"))
        }
        if (this.state.public_holidays === "1" && this.state.days === "") {
          return this.get_title()
        }
        if (this.state.public_holidays === "" && this.state.days !== "") {
          return ""
        }
        return "On ".concat(window.joinArray(days, 3, "and", "other"))
      },
    },
    // step 9
    {
      name: t("js.awards.form.title.time"),
      checkbox_accordion: true,
      opened: true,
      is_valid: true,
      is_unchecked: function () {
        this.element.find("#award_times").val("") &&
          this.element.find("#award_start_times").val("") &&
          this.element.find("#award_end_times").val("") &&
          this.element.find(".advanced-options").hide() &&
          this.element.find("select").val("1").trigger("change")
        this.element.find(".time-input").removeClass("active")
        this.element.find("#new-hours-container").hide()
        this.element.find(".time-inputs-overarching").find(".time-input:not(:first-of-type)").remove()
        this.element.find("#award_only_time_worked").val("1") &&
          this.element.find(".advanced-options").find(".option-box").first().addClass("selected")
        this.element.find(".option .option-box").removeClass("selected")
      },
      title_updater: function () {
        if (this.state.times === "" && this.state.start_times === "" && this.state.end_times === "") {
          return ""
        }
        const res = this.element
          .find(".time-input-parent")
          .map(function () {
            let text = window.$(this).parents(".option").find(".option-box").find("span").text().trim()
            let any_valid = false

            each(this.time_input_instances, function (instance) {
              const valid = instance.validate()
              if (valid && valid !== "empty") {
                any_valid = true
                text = text + ": " + instance.title_val()
              }
            })

            return any_valid ? text : null
          })
          .get()
        const compacted = compact(res)
        if (compacted.length > 1) {
          return t("js.awards.form.title_updater.various_times")
        }
        return compacted[0]
      },
      after_state_update: function () {
        const default_option = window.$(".default-option-info")
        if (!(this.state.times === "" && this.state.start_times === "" && this.state.end_times === "")) {
          default_option.addClass("dull")
        } else {
          default_option.removeClass("dull")
        }

        if (this.opened) {
          chain(this.state)
            .keys()
            .filter(function (key) {
              return (
                /disapply_if_weekend_location_close_after_time/.test(key) ||
                /disapply_if_weekday_location_close_after_time/.test(key)
              )
            })
            .each(
              function (key) {
                const hiddenField = this.element.find("#award_" + key)
                const value = this.state[key]
                if (hiddenField.siblings(".option-box").hasClass("selected")) {
                  hiddenField.val(value)
                } else {
                  hiddenField.val("")
                }
              }.bind(this)
            )
            .value()
        }
        if (this.opened) {
          chain(this.state)
            .keys()
            .filter(function (key) {
              return /times/.test(key)
            })
            .each(
              function (key) {
                this.element.find("#award_" + key).val(this.state[key])
              }.bind(this)
            )
            .value()
        }
        if (this.element.find(".option.time-input-toggle-parent-js .option-box").hasClass("selected")) {
          this.element.find(".specific-time-prompt").hide()
        } else {
          this.element.find(".specific-time-prompt").show()
        }
        if (!this.element.find("#worked-between-container .option-box").hasClass("selected")) {
          this.element.find(".time-inputs-overarching").find(".time-input:not(:first-of-type)").remove()
        }
      },
      validator: function validator() {
        return this.state.times !== "" || this.state.start_times !== "" || this.state.end_times !== ""
      },
    },
    // step 10
    {
      name: t("js.awards.form.title.tagged_shifts"),
      checkbox_accordion: true,
      opened: true,
      is_valid: true,
      is_unchecked: function () {
        this.element.find(".tabbed-view").hide()
        this.element.find("#award_shift_tags").val("")
        this.element.find("#award_not_shift_tags").val("")
        this.element.find(".option-box").removeClass("selected")
      },
      validator: function validator() {
        if (!this.state.award_tag_button && !this.state.teams_tag_button) {
          // neither award tags or teams is selected
          // we are not valid
          return false
        }

        // if one of the modes is ticked, we figure out if include or exclude has been selected and correctly filled
        const award_invalid = this.state.award_tag_button && !this.state.award_include && !this.state.award_exclude
        const team_invalid = this.state.teams_tag_button && !this.state.teams_include && !this.state.teams_exclude

        this.element.find("#award-tag-opt-awards").toggleClass("invalid", !!award_invalid)
        this.element.find("#team-opt-awards").toggleClass("invalid", !!team_invalid)

        const state = this.state
        each(["award", "teams"], function (tag) {
          if (!state[tag + "_tag_button"]) {
            each(["include", "exclude"], function (term) {
              state[tag + "_" + term] = ""
            })
          }
        })
        return !award_invalid && !team_invalid
      },
      title_updater: function () {
        const tags = this.state.award_include !== "" || this.state.award_exclude !== "" ? "Award Tag(s)" : ""
        const teams = this.state.teams_include !== "" || this.state.teams_exclude !== "" ? "Team(s)" : ""
        const with_tags = compact([tags, teams]).join(" and ")

        return with_tags
      },
      after_state_update: function () {
        if (this.state.award_tag_button || this.state.teams_tag_button) {
          this.state.shift_tags = get_tag_values(this.state.award_include, this.state.teams_include)
          this.state.not_shift_tags = get_tag_values(this.state.award_exclude, this.state.teams_exclude)
          // update the actual elements, too
          this.element.find("#award_shift_tags").val(this.state.shift_tags)
          this.element.find("#award_not_shift_tags").val(this.state.not_shift_tags)
        } else {
          // clear out these fields
          this.element.find("#award_shift_tags").val("")
          this.element.find("#award_not_shift_tags").val("")
        }
      },
    },
    // Step 11
    {
      name: "Which leave type should it apply to?",
      checkbox_accordion: true,
      opened: true,
      is_valid: true,
      is_unchecked: function () {
        this.element.find("#award_applies_to_leave_types").val("")
        this.element.find("#award_all_leave_types").val("0")
        this.element.find(".leave-type-select-js").data("clearSelected")()
        this.element.find(".option-box").removeClass("selected")
        this.element.find("#leave_export_name_container").val("")
        this.element.find("#leave_export_name_container").hide()
      },
      title_updater: function title_updater() {
        if (this.state.all_leave_types === "1") {
          return t("js.awards.form.title_updater.all_leave_types")
        } else if (this.state.applies_to_leave_types.length > 0) {
          const instance = this.element.find("select").get(0).filter_select_instance
          if (instance) {
            return "Leave Types: ".concat(window.joinArray(instance.val(), 2, "and", "other types"))
          }
          return ""
        }

        return false
      },
      after_state_update: function after_state_update() {
        if (this.element.find("#award_all_leave_types").val() === "1") {
          // If All types is selected; remove entries in leave types
          this.element.find("#leave_export_name_container").show()
          return this.element.find(".leave-type-select-js").data("clearSelected")()
        } else if (this.state.applies_to_leave_types !== "") {
          this.element.find("#leave_export_name_container").show()
          return this.element.find("#award_all_leave_types").val("0")
        } else {
          // turn all types off
          this.element.find("#leave_export_name_container").hide()
          return this.element.find("#award_all_leave_types").val("0")
        }
      },
      validator: function validator() {
        return this.state.all_leave_types === "1" || this.state.applies_to_leave_types !== ""
      },
    },
    // step 12
    {
      name: t("js.awards.form.title.shift_padding"),
      checkbox_accordion: true,
      opened: true,
      is_valid: true,
      is_unchecked: function () {
        this.element.find("#award_min_length").val("")
        this.element.find("#award_min_length_week").val("")
      },
      title_updater: function () {
        const shift_padding_value = this.element.find("#award_min_length").val()
        const shift_padding_week_value = this.element.find("#award_min_length_week").val()

        const msg = []

        if (shift_padding_value > 0) {
          msg.push(t("js.awards.form.subtitle.shift_padding.daily", { hours: shift_padding_value }))
        }

        if (shift_padding_week_value > 0) {
          msg.push(t("js.awards.form.subtitle.shift_padding.weekly", { hours: shift_padding_week_value }))
        }

        return msg.join(", ")
      },
      validator: function validation() {
        return (
          (this.state.min_length !== "" && this.state.min_length !== "0") ||
          (this.state.min_length_week !== "" && this.state.min_length_week !== "0")
        )
      },
    },
    // step 13
    {
      name: t("js.awards.form.title.rest_shifts"),
      checkbox_accordion: true,
      opened: true,
      is_valid: true,
      is_unchecked: function () {
        this.element.find("#shift_gap_input").val("")
        this.element.find("#shift_gap_ord_hours_input").val("")
        toggleHideShiftGapEntireHoursCheckbox()
      },
      validator: function validator() {
        const input1 = parseFloat(this.element.find("#shift_gap_input").val())
        const input2 = parseFloat(this.element.find("#shift_gap_ord_hours_input").val())

        return !!((input1 && input1 > 0) || (input2 && input2 > 0 && input2 <= 24))
      },
      title_updater: function () {
        const ends_in_overtime_checkbox = this.element.find("#shift_ends_in_overtime")
        const shift_gap_input = this.element.find("#shift_gap_input")
        const shift_gap_ord_hours_input = this.element.find("#shift_gap_ord_hours_input")
        const shift_gap_ord_hours_entire_shift = this.element.find("#ord-hours-entire-shift input[type='checkbox']")

        if (shift_gap_input.val() > 0) {
          return `< ${shift_gap_input.val()} hours between shifts ${
            ends_in_overtime_checkbox.prop("checked") ? "(ending in overtime)" : ""
          }`
        }

        if (shift_gap_ord_hours_input.val() > 0 && !shift_gap_ord_hours_entire_shift.is(":checked")) {
          return `< ${shift_gap_ord_hours_input.val()} hours since last ordinary hours worked`
        }

        if (shift_gap_ord_hours_input.val() > 0 && shift_gap_ord_hours_entire_shift.is(":checked")) {
          return `< ${shift_gap_ord_hours_input.val()} hours since last ordinary hours worked (apply entire shift)`
        }
      },
      after_state_update: function () {
        const ends_in_overtime_checkbox = this.element.find("#shift_ends_in_overtime")
        const shift_gap_input = this.element.find("#shift_gap_input")
        const shift_gap_ord_hours_input = this.element.find("#shift_gap_ord_hours_input")
        const shift_gap_input_focused = shift_gap_input.is(":focus")
        const shift_gap_ord_hours_input_focused = shift_gap_ord_hours_input.is(":focus")
        const shift_gap = this.element.find("#award_shift_gap")
        const shift_gap_all_hours = this.element.find("#award_shift_gap_all_hours")
        const shift_gap_ord_hours = this.element.find("#award_shift_gap_ord_hours")
        const last_shift_visual = this.element.find(".flexible-visual-container")
        const overtime_worked = this.element.find(".overtime-worked-visual-container")

        // clear or fallback inputs to previous if they don't fall in their respective valid range
        if (shift_gap_input.val()) {
          if (shift_gap_input.val() <= 0 && !shift_gap_input_focused) {
            shift_gap_input.val(shift_gap.val() || shift_gap_all_hours.val())
          }
        } else {
          shift_gap.val("")
          shift_gap_all_hours.val("")
        }

        if (shift_gap_ord_hours_input.val()) {
          if (
            (shift_gap_ord_hours_input.val() <= 0 || shift_gap_ord_hours_input.val() > 24) &&
            !shift_gap_ord_hours_input_focused
          ) {
            shift_gap_ord_hours_input.val(shift_gap_ord_hours.val())
          }
        } else {
          shift_gap_ord_hours.val("")
        }

        // clear alternate inputs, award values, and disable fields if current input has a valid value
        if (shift_gap_input.val() > 0 && !shift_gap_input_focused) {
          if (ends_in_overtime_checkbox.prop("checked")) {
            shift_gap_all_hours.val("")
            shift_gap.val(shift_gap_input.val())
          } else {
            shift_gap.val("")
            shift_gap_all_hours.val(shift_gap_input.val())
          }
          shift_gap_ord_hours_input.prop("disabled", true)
        } else {
          shift_gap_ord_hours_input.prop("disabled", false)
        }
        if (
          shift_gap_ord_hours_input.val() > 0 &&
          shift_gap_ord_hours_input.val() <= 24 &&
          !shift_gap_ord_hours_input_focused
        ) {
          shift_gap_ord_hours.val(shift_gap_ord_hours_input.val())
          shift_gap_input.val("")
          shift_gap.val("")
          shift_gap_all_hours.val("")
          shift_gap_input.prop("disabled", true)
        } else {
          shift_gap_input.prop("disabled", false)
        }

        // display shift end OT visual if checkbox is ticked
        if (ends_in_overtime_checkbox.prop("checked")) {
          last_shift_visual.addClass("add-overtime")
          overtime_worked.addClass("add-overtime")
        } else {
          last_shift_visual.removeClass("add-overtime")
          overtime_worked.removeClass("add-overtime")
        }
        toggleHideShiftGapEntireHoursCheckbox()
        toggleShiftGapEntireHoursCheckboxValue()
      },
    },
    // Step 14
    {
      name: t("js.awards.form.title.late_break_overtime"),
      checkbox_accordion: true,
      opened: true,
      is_valid: true,
      is_unchecked: function () {
        this.element.find("#award_late_break_overtime").prop("checked", false)
        this.element.find("#award_hours_worked_until_break").val("")
        this.element.find("#award_min_required_break_length").val("")
      },
      validator: function validator() {
        const hrs_worked = this.element.find("#award_hours_worked_until_break").val()
        const req_break_len = this.element.find("#award_min_required_break_length").val()

        if ((hrs_worked !== "" || req_break_len !== "") && hrs_worked > 0 && req_break_len > 0) {
          return true
        }

        return !!this.state.late_break_overtime
      },
      on_form_load: function () {
        const hrs_worked = this.element.find("#award_hours_worked_until_break").val()
        const req_break_len = this.element.find("#award_min_required_break_length").val()

        if (!(hrs_worked > 0 && req_break_len > 0)) {
          this.element.find("#award_hours_worked_until_break").val("")
          this.element.find("#award_min_required_break_length").val("")
        }
      },
    },
    // step 15
    {
      name: t("js.awards.form.title.hd_overflow"),
      checkbox_accordion: true,
      opened: true,
      is_valid: true,
      is_unchecked: function () {
        this.element.find(".tabbed-view").hide()
        this.element.find("#award_higher_duties_overflow_tags").val("")
        this.element.find("#award_higher_duties_overflow_hours").val("")
        this.element.find(".option-box").removeClass("selected")
      },
      validator: function validator() {
        if (!this.state.hd_overflows_award_tag_button && !this.state.hd_overflows_teams_tag_button) {
          // neither award tags or teams is selected
          // we are not valid
          return false
        }

        if (!this.state.higher_duties_overflow_hours) {
          return false
        }

        // if one of the modes is ticked, we figure out if include or exclude has been selected and correctly filled
        const award_invalid = this.state.hd_overflows_award_tag_button && !this.state.hd_overflows_award_include
        const team_invalid = this.state.hd_overflows_teams_tag_button && !this.state.hd_overflows_teams_include

        this.element.find("#hd-overflow-award-tag-opt-awards").toggleClass("invalid", !!award_invalid)
        this.element.find("#hd-overflow-team-opt-awards").toggleClass("invalid", !!team_invalid)

        const state = this.state
        each(["award", "teams"], function (tag) {
          if (!state[tag + "_tag_button"]) {
            state[tag + "_include"] = ""
          }
        })
        return !award_invalid && !team_invalid
      },
      title_updater: function () {
        const tags = this.state.hd_overflows_award_include !== "" ? "Award Tag(s)" : ""
        const teams = this.state.hd_overflows_teams_include !== "" ? "Team(s)" : ""
        const with_tags = compact([tags, teams]).join(" and ")
        const hours = this.state.higher_duties_overflow_hours

        return with_tags + " for " + hours + " hours"
      },
      after_state_update: function () {
        if (this.state.hd_overflows_award_tag_button || this.state.hd_overflows_teams_tag_button) {
          this.state.higher_duties_overflow_tags = get_tag_values(
            this.state.hd_overflows_award_include,
            this.state.hd_overflows_teams_include
          )
          // update the actual elements, too
          this.element.find("#award_higher_duties_overflow_tags").val(this.state.higher_duties_overflow_tags)
        } else {
          // clear out these fields
          this.element.find("#award_higher_duties_overflow_tags").val("")
        }
      },
    },
    // step 16
    {
      name: t("js.awards.form.title.rate"),
      validator: function validator() {
        const has_valid_value =
          !!this.state.hourly_rate ||
          !!this.state.multiplier ||
          !!this.state.base_rate_percentage ||
          !!this.state.base_rate_addition ||
          !!this.state.base_rate_minimum ||
          !!this.state.base_rate_override ||
          !!this.state.included_in_blended_ot_button
        if (has_valid_value) {
          return true
        }

        const payroll_link_step = this.rule.steps[16] // refers to step 17
        const backup_validator = payroll_link_step.unpaid_or_accrual
        return backup_validator && backup_validator.call(payroll_link_step)
      },
      on_form_load: function () {
        if (this.element.find("#award_multiplier").val()) {
          this.element.find("[data-shows='#multiplier-awards']").addClass("selected")
        } else if (this.element.find("#award_hourly_rate").val()) {
          this.element.find("[data-shows='#cost-awards']").addClass("selected")
        }

        const has_additional_pct = this.element.find("#award_base_rate_percentage").val()
        const has_additional_cost = this.element.find("#award_base_rate_addition").val()
        const has_additional_minimum = this.element.find("#award_base_rate_minimum").val()
        const has_additional_override = this.element.find("#award_base_rate_override").val()

        if (has_additional_cost || has_additional_pct || has_additional_minimum || has_additional_override) {
          this.element.find(".advanced-link").addClass("open")
          this.element.find(".advanced-options").show()
          this.element.find("[data-shows='#base-rate-options']").addClass("selected")

          if (has_additional_pct) {
            this.element.find("[data-shows='#base_rate_percentage']").addClass("selected")
          } else if (has_additional_cost) {
            this.element.find("[data-shows='#base_rate_cost']").addClass("selected")
          } else if (has_additional_minimum) {
            this.element.find("[data-shows='#base_rate_minimum']").addClass("selected")
          } else if (has_additional_override) {
            this.element.find("[data-shows='#base_rate_override']").addClass("selected")
          }
        }
      },
      after_state_update: function () {
        const base_rate_checkbox = window.$("#base-rate-checkbox")
        const base_rate_options = this.element.find("#base-rate-options").find(".option-box")
        const input = this.element.find("#base-rate-options").find(".stretch-input")

        if (!base_rate_checkbox.hasClass("selected")) {
          base_rate_options.removeClass("selected")
          input.hide()
          input.find("input").val("")
        }
      },
      title_updater: function () {
        const button = this.element.find(".radio.selected")
        const text = button.find(".heading").text()
        const input = parseFloat(this.element.find(button.data("shows")).find("input").val())
        const base_rate_checkbox = this.element.find("#base-rate-options").find(".selected")
        const output = []
        const br_text = base_rate_checkbox.find("span").text()
        const br_input = parseFloat(this.element.find(base_rate_checkbox.data("shows")).find("input").val())
        const currency = window.i18n_props.currency.unit || "$"
        if ((!isNaN(input) && input > 0) || text === "Blended Overtime") {
          switch (text) {
            case "Specific cost":
              output.push(text.concat(`: ${currency}`, input.toFixed(2)))
              break
            case "Multiplier":
              output.push(text.concat(": x", input))
              break
            case "Blended Overtime":
              output.push(text.concat(" rate"))
              break
          }
        }

        if (!isNaN(br_input)) {
          switch (br_text) {
            case "Additional percentage":
              output.push(br_text.concat(": ", br_input, "%"))
              break
            case "Additional cost":
              output.push(br_text.concat(`: ${currency}`, br_input))
              break
            case "Minimum rate":
              output.push(br_text.concat(`: ${currency}`, br_input))
              break
            case "Override rate":
              output.push(br_text.concat(`: ${currency}`, br_input))
              break
          }
        }

        return output.join(" and ")
      },
    },
    // step 17
    {
      name: t("js.awards.form.title.link_to_payroll"),
      unpaid_or_accrual: function () {
        return this.element.find("#award_unpaid").is(":checked") || this.element.find("#award_accrual").is(":checked")
      },
      title_updater: function () {
        if (this.element.find("#award_export_name").val() === "") {
          return t("js.awards.form.title_updater.no_export_name")
        }

        return window.$("#award_export_name").val()
      },
    },
  ]

  const rule = (window.rule = new window.Ruleable("award", ".award-rules-accordion", steps, default_params))
  init_filter_selects(rule)
  toggleAccordionSubsection()
  removeSubAccordionOptions()
  toggleTimeInput()
  toggleSingleTimeInput()
  addTimeRow()
  preventTimeDefault()
  preventSingleTimeDefault()
  toggleAccordionBorder()
  configureOrdinaryHoursToggle()
  handleDaysWorkedVisual()
  checkShowRosteredHours()
  toggleRosterdHoursCheckboxes()
  rule.container.find(".multi-select-js").multi_select()
  rule.container.find(".select-box").selectInput()
  rule.container.find(".time-input-parent").time_input()
  rule.container.find(".single-time-input-parent").single_time_input()
  window.$(".range-input").range_input()
  window.$(".rule-section").bind(".accordion-group", function () {
    if (window.$(".rule-section").children(".accordion-group").hasClass("complete")) {
      window.$(".rule-section").addClass("complete")
    }
  })

  rule.steps[0].open_accordion()

  window.$("form[id*=award]").submit(function () {
    rule.prepare_for_post()
    return true // continue as you were
  })

  return rule
}

export default { init }
