// @flow
import * as Sentry from "@sentry/browser"

const enabledEnvironments = ["development", "staging", "production"]

const isEnabled = enabledEnvironments.includes(process.env.TARGET_RAILS_ENV)
const commitSha = process.env.COMMIT_SHA || "unknown"
const payausUrls = [/https?:\/\/.*\.tanda\.co/, /https?:\/\/.*\.workforce\.com/, /https?:\/\/.*\.dev.payaus.adnat.co/]

if (isEnabled) {
  Sentry.init({
    dsn: "https://c30feb3a276a819103e8d7f2c210c661@o4506793588555776.ingest.us.sentry.io/4507847337181184",
    // I am unsure how to get commit hash here
    release: `payaus-${commitSha}`,
    environment: process.env.TARGET_RAILS_ENV,
    allowUrls: payausUrls,

    // This is error sampling:
    // https://docs.sentry.io/platforms/javascript/configuration/options/#sample-rate
    sampleRate: 1,

    // No perf sampling from frontend.
    // https://docs.sentry.io/platforms/javascript/configuration/options/#tracing-options
    enableTracing: true,
    tracesSampleRate: 0.00125,
    integrations: [Sentry.browserTracingIntegration()],
    tracePropagationTargets: payausUrls,

    ignoreErrors: [
      "__gCrWeb.autofill.extractForms",
      "Background Sync is disabled",
      "Request failed with status code",
      "maininit is not defined",
      "$ is not defined",
      "__firefox__",
      /_isMatchingDomain is not defined/,
      /Failed to execute 'postMessage' on 'Window':/,
      /AbortError/,
      /createError/,
      /node_modules/,
    ],
    denyUrls: ["js.intercomcdn.com"],
    beforeSend(event) {
      if (event.user) {
        delete event.user.username
        delete event.user.email
      }

      return event
    },
  })

  window.current_user && Sentry.setUser({ id: window.current_user.id })

  const euUrl = /^https?:\/\/.*.?eu\.(workforce|tanda)/
  const region = window.location.href.match(euUrl) ? "frankfurt" : "sydney"

  Sentry.setTag("region", region)
}
